import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";
import type { MetaFunction, LoaderFunction } from "@remix-run/node";
import { json } from "@remix-run/node";
import "./tailwind.css";
import type React from "react";
import Header from "./components/Header";
import ScrollToTop from "./utils/scrollToTop";
import { schoolYear } from "./data/schoolYear.server";
import { useTranslation } from "react-i18next";
import { useChangeLanguage } from "remix-i18next/react";
import i18nServer, { i18nCookie } from "~/utils/i18n.server";



// export const meta: MetaFunction = () => {
//   return { title: "New Student Registration" };
// };

// comment test

type LoaderData = {
  locale: string;
};

export const loader: LoaderFunction = async ({ request }) => {
  const locale = await i18nServer.getLocale(request);
  console.log("locale from root", locale);

  return json<LoaderData>({ locale }, {
    headers: {
      "Set-Cookie": await i18nCookie.serialize(locale),
    },

  });
};

export const handle = {
  i18n: [],
};

function Document({
  children,
  title = "AISD New Student Registration",
  lang,
  dir
}: {
  children: React.ReactNode;
  title?: string;
  lang?: string;
  dir?: string;
}) {
  return (
    <html lang={lang} dir={dir} >
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <title>{title}</title>
        <Meta />
        <Links />
      </head>
      <body className=" bg-gradient-to-b from-blue-600 to-blue-400 bg-no-repeat min-h-screen print:bg-gradient-to-b print:from-white print:to-white">
        {children}
        <ScrollToTop />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default function App() {
  const { locale } = useLoaderData<LoaderData>();
  const { i18n } = useTranslation();
  console.log("locale from App", locale);

  useChangeLanguage(locale);
  return (
    <Document lang={locale} dir={i18n.dir()} >
      <Outlet />
    </Document>
  );
}

export function ErrorBoundary() {
  const error = useRouteError()
  if (isRouteErrorResponse(error)) {
    return (
      <div>
        <h1>Oops</h1>
        <p>Status: {error.status}</p>
        <p>{error.data.message}</p>
      </div>
    );
  }

  // Don't forget to typecheck with your own logic.
  // Any value can be thrown, not just errors!
  let errorMessage = "Unknown error";
  if (error instanceof Error) {
    errorMessage = error.message;
  }

  return (
    <div>
      <h1>Uh oh ...</h1>
      <p>Something went wrong.</p>
      <pre>{errorMessage}</pre>
    </div>
  );
}
